$geometria-font-path: "./Geometria" !default;

@font-face {
  font-family: Geometria;
  font-style: italic;
  font-weight: 200;
  src:
    url("#{$geometria-font-path}/Geometria-ExtraLightItalic.woff2") format("woff2"),
    url("#{$geometria-font-path}/Geometria-ExtraLightItalic.woff") format("woff"),
    url("#{$geometria-font-path}/Geometria-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: Geometria;
  font-style: normal;
  font-weight: 800;
  src:
    url("#{$geometria-font-path}/Geometria-ExtraBold.woff2") format("woff2"),
    url("#{$geometria-font-path}/Geometria-ExtraBold.woff") format("woff"),
    url("#{$geometria-font-path}/Geometria-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: Geometria;
  font-style: normal;
  font-weight: 100;
  src:
    url("#{$geometria-font-path}/Geometria-Thin.woff2") format("woff2"),
    url("#{$geometria-font-path}/Geometria-Thin.woff") format("woff"),
    url("#{$geometria-font-path}/Geometria-Thin.ttf") format("truetype");
}

@font-face {
  font-family: Geometria;
  font-style: normal;
  font-weight: 300;
  src:
    url("#{$geometria-font-path}/Geometria-Light.woff2") format("woff2"),
    url("#{$geometria-font-path}/Geometria-Light.woff") format("woff"),
    url("#{$geometria-font-path}/Geometria-Light.ttf") format("truetype");
}

@font-face {
  font-family: Geometria;
  font-style: normal;
  font-weight: bold;
  src:
    url("#{$geometria-font-path}/Geometria-Bold.woff2") format("woff2"),
    url("#{$geometria-font-path}/Geometria-Bold.woff") format("woff"),
    url("#{$geometria-font-path}/Geometria-Bold.ttf") format("truetype");
}

@font-face {
  font-family: Geometria;
  font-style: italic;
  font-weight: 100;
  src:
    url("#{$geometria-font-path}/Geometria-ThinItalic.woff2") format("woff2"),
    url("#{$geometria-font-path}/Geometria-ThinItalic.woff") format("woff"),
    url("#{$geometria-font-path}/Geometria-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: Geometria;
  font-style: italic;
  font-weight: bold;
  src:
    url("#{$geometria-font-path}/Geometria-BoldItalic.woff2") format("woff2"),
    url("#{$geometria-font-path}/Geometria-BoldItalic.woff") format("woff"),
    url("#{$geometria-font-path}/Geometria-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: Geometria;
  font-style: italic;
  font-weight: 900;
  src:
    url("#{$geometria-font-path}/Geometria-HeavyItalic.woff2") format("woff2"),
    url("#{$geometria-font-path}/Geometria-HeavyItalic.woff") format("woff"),
    url("#{$geometria-font-path}/Geometria-HeavyItalic.ttf") format("truetype");
}

@font-face {
  font-family: Geometria;
  font-style: italic;
  font-weight: 500;
  src:
    url("#{$geometria-font-path}/Geometria-MediumItalic.woff2") format("woff2"),
    url("#{$geometria-font-path}/Geometria-MediumItalic.woff") format("woff"),
    url("#{$geometria-font-path}/Geometria-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: Geometria;
  font-style: italic;
  font-weight: normal;
  src:
    url("#{$geometria-font-path}/Geometria-Italic.woff2") format("woff2"),
    url("#{$geometria-font-path}/Geometria-Italic.woff") format("woff"),
    url("#{$geometria-font-path}/Geometria-Italic.ttf") format("truetype");
}

@font-face {
  font-family: Geometria;
  font-style: normal;
  font-weight: 900;
  src:
    url("#{$geometria-font-path}/Geometria-Heavy.woff2") format("woff2"),
    url("#{$geometria-font-path}/Geometria-Heavy.woff") format("woff"),
    url("#{$geometria-font-path}/Geometria-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: Geometria;
  font-style: normal;
  font-weight: normal;
  src:
    url("#{$geometria-font-path}/Geometria.woff2") format("woff2"),
    url("#{$geometria-font-path}/Geometria.woff") format("woff"),
    url("#{$geometria-font-path}/Geometria.ttf") format("truetype");
}

@font-face {
  font-family: Geometria;
  font-style: italic;
  font-weight: 300;
  src:
    url("#{$geometria-font-path}/Geometria-LightItalic.woff2") format("woff2"),
    url("#{$geometria-font-path}/Geometria-LightItalic.woff") format("woff"),
    url("#{$geometria-font-path}/Geometria-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: Geometria;
  font-style: normal;
  font-weight: 200;
  src:
    url("#{$geometria-font-path}/Geometria-ExtraLight.woff2") format("woff2"),
    url("#{$geometria-font-path}/Geometria-ExtraLight.woff") format("woff"),
    url("#{$geometria-font-path}/Geometria-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: Geometria;
  font-style: italic;
  font-weight: 800;
  src:
    url("#{$geometria-font-path}/Geometria-ExtraBoldItalic.woff2") format("woff2"),
    url("#{$geometria-font-path}/Geometria-ExtraBoldItalic.woff") format("woff"),
    url("#{$geometria-font-path}/Geometria-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: Geometria;
  font-style: normal;
  font-weight: 500;
  src:
    url("#{$geometria-font-path}/Geometria-Medium.woff2") format("woff2"),
    url("#{$geometria-font-path}/Geometria-Medium.woff") format("woff"),
    url("#{$geometria-font-path}/Geometria-Medium.ttf") format("truetype");
}
