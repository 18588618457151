/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Swiper JS styles */
@import "swiper/scss";
@import "@ionic/angular/css/ionic-swiper";
@import "swiper/scss/pagination";
@import "swiper/scss/autoplay";
@import "swiper/scss/virtual";

// с помощью этого фиксим белый экран на iOS <= 13.3
.ion-page {
  width: 100%;
  height: 100%;
}

// fix работы ссылок для iOS <= 15.0
a {
  pointer-events: initial;
}

// отключаем иконку с "часами" для инпутов с типом "time"
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

// стили для модального окна онбординга с типом "свайп"
.swipe-onboarding-modal {
  --width: 800px;
  --border-radius: 10px;

  &::part(backdrop) {
    background-color: black;

    --backdrop-opacity: 0.25;
  }
}

ion-toast {
  &.announcement-info {
    --color: #5aacd8;
    --button-color: #5aacd8;
  }

  &.announcement-issue {
    --color: #ffab00;
    --button-color: #ffab00;
  }
}

.selection-input {
  .native-input.sc-ion-input-md,
  .native-input.sc-ion-input-ios {
    cursor: pointer;
  }
}

// включаем возможность переноса текста внутри ion-select-popover
.sc-ion-select-popover-md,
.sc-ion-select-popover-ios {
  white-space: normal !important;
}

// включаем возможность переноса текста в ion-alert
.alert-tappable.sc-ion-alert-md,
.alert-tappable.sc-ion-alert-ios {
  height: initial;
  contain: initial;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
}

ion-alert {
  .text-grey {
    color: var(--ion-color-grey);
  }

  .text-primary {
    color: var(--ion-color-primary);
    font-weight: bold;
  }
}

ion-content.ion-bottom-drawer-scrollable-content {
  --background: transparent;
}

.unsubscription-alert .alert-message {
  max-height: none;
}

@for $i from 1 through 5 {
  .text-ellipsis-#{$i}-row {
    // stylelint-disable-next-line property-no-vendor-prefix
    -moz-box-orient: vertical;
    // stylelint-disable-next-line property-no-vendor-prefix
    -webkit-box-orient: vertical;
    // stylelint-disable-next-line value-no-vendor-prefix
    display: -moz-box;
    // stylelint-disable-next-line value-no-vendor-prefix
    display: -webkit-box;
    -webkit-line-clamp: $i;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.notification-toast {
  cursor: pointer;
}

ion-segment {
  margin: 0 1rem;

  --background: #e5ebf3;

  &-button {
    height: 2rem;

    --indicator-color: var(--ion-color-primary);
  }
}

ion-checkbox {
  --checkmark-width: 3px;
}

ion-button {
  --background-hover-opacity: 0;
  --background-focused-opacity: 0;
  --background-activated-opacity: 0;
}

/* кастомные иониковские цвета */

:root {
  --ion-color-custom-dark: #2d3542;
  --ion-color-custom-dark-rgb: 45, 53, 66;
  --ion-color-custom-dark-contrast: #fff;
  --ion-color-custom-dark-contrast-rgb: 255, 255, 255;
  --ion-color-custom-dark-shade: #282f3a;
  --ion-color-custom-dark-tint: #424955;
  --ion-color-custom-grey: #3d4553;
  --ion-color-custom-grey-rgb: 61, 69, 83;
  --ion-color-custom-grey-contrast: #fff;
  --ion-color-custom-grey-contrast-rgb: 255, 255, 255;
  --ion-color-custom-grey-shade: #363d49;
  --ion-color-custom-grey-tint: #505864;
  --ion-color-custom-green: #2affee;
  --ion-color-custom-green-rgb: 42, 255, 238;
  --ion-color-custom-green-contrast: #000;
  --ion-color-custom-green-contrast-rgb: 0, 0, 0;
  --ion-color-custom-green-shade: #25e0d1;
  --ion-color-custom-green-tint: #3ffff0;
  --ion-color-custom-orange: #f26122;
  --ion-color-custom-orange-rgb: 242, 97, 34;
  --ion-color-custom-orange-contrast: #000;
  --ion-color-custom-orange-contrast-rgb: 0, 0, 0;
  --ion-color-custom-orange-shade: #d5551e;
  --ion-color-custom-orange-tint: #f37138;
}

.ion-color-custom-dark {
  --ion-color-base: var(--ion-color-custom-dark);
  --ion-color-base-rgb: var(--ion-color-custom-dark-rgb);
  --ion-color-contrast: var(--ion-color-custom-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-custom-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-custom-dark-shade);
  --ion-color-tint: var(--ion-color-custom-dark-tint);
}

.ion-color-custom-grey {
  --ion-color-base: var(--ion-color-custom-grey);
  --ion-color-base-rgb: var(--ion-color-custom-grey-rgb);
  --ion-color-contrast: var(--ion-color-custom-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-custom-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-custom-grey-shade);
  --ion-color-tint: var(--ion-color-custom-grey-tint);
}

.ion-color-custom-green {
  --ion-color-base: var(--ion-color-custom-green);
  --ion-color-base-rgb: var(--ion-color-custom-green-rgb);
  --ion-color-contrast: var(--ion-color-custom-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-custom-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-custom-green-shade);
  --ion-color-tint: var(--ion-color-custom-green-tint);
}

.ion-color-custom-orange {
  --ion-color-base: var(--ion-color-custom-orange);
  --ion-color-base-rgb: var(--ion-color-custom-orange-rgb);
  --ion-color-contrast: var(--ion-color-custom-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-custom-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-custom-orange-shade);
  --ion-color-tint: var(--ion-color-custom-orange-tint);
}

// стили для кастомного модального окна календаря событий
.calendar-custom-modal {
  --background: var(--ion-color-brand);
  --height: 90%;

  &.mobile-modal {
    --height: 100%;
  }

  &::part(content) {
    --background: transparent;

    backdrop-filter: blur(2px);
  }

  &::part(backdrop) {
    background-color: transparent;
  }
}

@media (max-width: 1199px) {
  .swipe-onboarding-modal {
    --border-radius: none;
  }
}

.penalty-payment-modal {
  --height: 100%;
  --inner-max-height: 845px;
  --overflow: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;
  }

  @media only screen and (min-width: 768px) and (min-height: 600px) {
    --height: auto;
    --width: 400px;
    --max-height: 845px;
  }
}
